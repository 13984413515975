import React, { useMemo } from 'react';
import { DEFAULT_USER_PROFILE } from '../../constants/urls';
var NameBlock = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'Hello World' : _b, _c = _a.nameSize, nameSize = _c === void 0 ? 'md' : _c, _d = _a.nameClassName, nameClassName = _d === void 0 ? '' : _d, _e = _a.title, title = _e === void 0 ? '' : _e, _f = _a.profilePicture, profilePicture = _f === void 0 ? DEFAULT_USER_PROFILE : _f, _g = _a.pictureSize, pictureSize = _g === void 0 ? 'md' : _g, _h = _a.className, className = _h === void 0 ? '' : _h, _j = _a.role, role = _j === void 0 ? '' : _j, _k = _a.testUser, testUser = _k === void 0 ? false : _k, _l = _a.membership, membership = _l === void 0 ? [] : _l, onClick = _a.onClick;
    var nameSizeClassName = useMemo(function () {
        if (nameSize === 'sm') {
            return 'tw-text-sm';
        }
        if (nameSize === 'md') {
            return 'tw-text-base';
        }
        return 'tw-text-lg';
    }, [nameSize]);
    var nameClassNames = useMemo(function () {
        var c = '';
        c += nameSizeClassName;
        if (nameClassName) {
            c += " ".concat(nameClassName);
        }
        return c;
    }, [nameClassName, nameSizeClassName]);
    var isActiveMembership = function (membership) { return membership.indexOf('inactive') === -1; };
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: 'tw-flex tw-items-center' },
            React.createElement("img", { "data-demo": 'disabled', className: "tw-bd-placeholder-img tw-rounded-full tw-mr-3 picture-size-".concat(pictureSize), src: profilePicture, alt: name }),
            React.createElement("div", { className: 'tw-flex tw-items-center' },
                React.createElement("div", null,
                    onClick ? (React.createElement("button", { className: "".concat(nameClassNames, " tw-m-0 btn-link text-left"), "data-demo": 'disabled', onClick: onClick, type: 'button' }, name)) : (React.createElement("div", { className: "".concat(nameClassNames, " tw-m-0"), "data-demo": 'disabled' }, name)),
                    title && (React.createElement("div", { className: 'tw-text-gray-400 text-nowrap' }, title)),
                    role || testUser || membership ? (React.createElement("div", { className: 'tw-flex' },
                        role && !title && (React.createElement("div", { className: 'tw-text-gray-400 tw-mr-2' }, role)),
                        testUser && (React.createElement("div", { className: 'tw-mr-1' },
                            React.createElement("span", { className: 'tw-inline-flex tw-items-center tw-rounded-md tw-bg-yellow-500 tw-px-2 tw-py-1 tw-text-xs tw-font-medium' }, "Test Account"))),
                        membership && !isActiveMembership(membership) && (React.createElement("div", null,
                            React.createElement("span", { className: 'tw-inline-flex tw-items-center tw-rounded-md tw-bg-red-500 tw-text-white tw-px-2 tw-py-1 tw-text-xs tw-font-medium' }, "Inactive"))))) : null)))));
};
export default NameBlock;
